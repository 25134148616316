// Hover

  @mixin hover {
    &:hover { @content; }
  }

  @mixin hover-focus {
    &:hover,
    &:focus {
      @content;
    }
  }

  @mixin plain-hover-focus {
    &,
    &:hover,
    &:focus {
      @content;
    }
  }

  @mixin hover-focus-active {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }

// Input Placeholder

  @mixin placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; } 
  }