.modal-backdrop {
  @apply transform ease-in-out duration-150 opacity-0 fixed inset-0 h-full w-full overflow-hidden bg-black pointer-events-none invisible cursor-none z-20;
  &.fade-in {
    @apply pointer-events-auto visible cursor-pointer opacity-50 z-10;
    animation-name: fadeInModalBackdrop;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.15s;
  }
}

.modal {
  @apply transform opacity-0 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none z-20 text-grey-anak;
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
    animation-name: modalShow;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.15s;
  }
  .show-slide {
    opacity: 1;
    visibility: visible;
    animation-name: modalShowSlide;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.15s;
  }
  .modal-header {
    @apply flex items-center justify-between p-4 border-b border-solid rounded-t;
    .modal-close {
      @apply ml-auto;
    }
  }
  .modal-content {
    @apply border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none;
  }
  .modal-footer {
    @apply flex items-center justify-end p-4 border-t border-solid rounded-b;
  }
}

@keyframes modalShow {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate(0, -10px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

.modal {
  @apply transform opacity-0 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none z-20;
  &.show-slide {
    opacity: 1;
    animation-name: modalShowSlide;
  }
}
@keyframes modalShowSlide {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateX(100%);
  }
}

[data-background='light'] {
  .modal {
    .modal-header {
      @apply bg-white text-grey-900 border-grey-200;
    }
    .modal-footer {
      @apply bg-white text-grey-900 border-grey-200;
    }
    .modal-content {
      @apply bg-white text-grey-900;
    }
  }
}

[data-background='dark'] {
  .modal {
    .modal-header {
      @apply bg-grey-800 text-white border-grey-700;
    }
    .modal-footer {
      @apply bg-grey-800 text-white border-grey-700;
    }
    .modal-content {
      @apply bg-grey-800 text-white;
    }
  }
}
