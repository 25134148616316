@media (max-width: $sm) {

  body{
    
    .ant-pagination{
      justify-content: center;

      .ant-pagination-total-text{
        width: 100%;
        text-align: center;
      }

      &.right-align{
        .ant-pagination-prev{
          margin-left: 0;
        }
      }
    }

    .tabs > div:first-child{
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    
    .tabs .tab{
      padding: 8px;
    }

    .mockup-phone{
      min-width: 325px;
      margin-top: 40px;
    }

    [data-layout="layout-1"] .wrapper .left-sidebar{
      max-width: calc(0.25rem * 64);
    }

    .org-table table tr > td {
          
      &:first-child{
        min-width: 70px;
      }
      
      &:nth-child(2){
        max-width: 180px;
      }
      
      &:nth-child(3){
        min-width: 140px;
      }
      
      &:nth-child(4){
        min-width: 180px;
      }
      
      &:nth-child(5){
        min-width: 120px;
      }
      
      &:last-child{
        min-width: 100px;
        text-align: center;
      }
    }
  }
}