// Blues

  $blue-50: #e3f2fd;
  $blue-100: #bbdefb;
  $blue-200: #90caf9;
  $blue-300: #64b5f6;
  $blue-400: #42a5f5;
  $blue-500: #2196f3;
  $blue-600: #1e88e5;
  $blue-700: #1976d2;
  $blue-800: #1565c0;
  $blue-900: #0d47a1;
  $blue-A100: #82b1ff;
  $blue-A200: #448aff;
  $blue-A400: #2979ff;
  $blue-A700: #2962ff;
  $blue: #007AFF;

// Reds

  $red-50: #ffebee;
  $red-100: #ffcdd2;
  $red-200: #ef9a9a;
  $red-300: #e57373;
  $red-400: #ef5350;
  $red-500: #f44336;
  $red-600: #e53935;
  $red-700: #d32f2f;
  $red-800: #c62828;
  $red-900: #b71c1c;
  $red-A100: #ff8a80;
  $red-A200: #ff5252;
  $red-A400: #ff1744;
  $red-A700: #d50000;
  $red: #F2423E;

// Green

  $green-50: #e8f5e9;
  $green-100: #c8e6c9;
  $green-200: #a5d6a7;
  $green-300: #81c784;
  $green-400: #66bb6a;
  $green-500: #4caf50;
  $green-600: #43a047;
  $green-700: #388e3c;
  $green-800: #2e7d32;
  $green-900: #1b5e20;
  $green-A100: #b9f6ca;
  $green-A200: #69f0ae;
  $green-A400: #00e676;
  $green-A700: #00c853;
  $green: #22C55E;

// Orange

  $orange-50: #fff3e0;
  $orange-100: #ffe0b2;
  $orange-200: #ffcc80;
  $orange-300: #ffb74d;
  $orange-400: #ffa726;
  $orange-500: #ff9800;
  $orange-600: #fb8c00;
  $orange-700: #f57c00;
  $orange-800: #ef6c00;
  $orange-900: #e65100;
  $orange-A100: #ffd180;
  $orange-A200: #ffab40;
  $orange-A400: #ff9100;
  $orange-A700: #ff6d00;
  $orange: #EB5E00;

// Grey

  $gray-50: #fafafa;
  $gray-100: #f5f5f5;
  $gray-200: #eeeeee;
  $gray-300: #e0e0e0;
  $gray-400: #bdbdbd;
  $gray-500: #9e9e9e;
  $gray-600: #757575;
  $gray-700: #616161;
  $gray-800: #424242;
  $gray-890: #3a3a3a;
  $gray-895: #2e2e2e;
  $gray-900: #212121;
  $black: #334155;
  $white: #fff;

// Fonts

$font-sans: "DM Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Grid Size

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;